'use client';
import { useRouter } from '@cxnpl/router';
import { Text, YStack } from '@cxnpl/ui';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/store';
import { MfaForm } from 'app/provider/StepUpAuth/MfaForm';
import { LoginForm } from './components';
import { useHandleLogin } from './handleLogin';

export function LoginPage() {
  const { t } = useTranslation();

  const router = useRouter();
  const showInactivityLogout = useAppSelector((state) => state.auth.inactivityLogout);

  const {
    loginError,
    setLoginError,
    onSubmitLogin,
    onSubmitMFA,
    setShowMFAForm,
    showMFAForm,
    onResendMFA,
    isDisabled,
  } = useHandleLogin();

  //WEB-ONLY: Prefetch other static routes
  useEffect(() => {
    router.prefetch('/help');
    router.prefetch('/signup');
  }, [router]);

  const onPressNeedHelp = () => {
    router.push('/help');
  };

  const onPressSignUp = () => {
    router.push('/signup');
  };

  return (
    <YStack
      width="100%"
      backgroundColor="$background/surface"
      padding="$space.xl"
      borderRadius="$surface/radius/surface-radius"
      gap="$space.2xl"
      $tablet={{
        maxWidth: 536,
        padding: '$space.8xl',
      }}
      $laptop={{
        maxWidth: 468,
        padding: '$space.5xl',
      }}
    >
      {showMFAForm ? (
        <Text
          variant="bodyMediumEm"
          link
          color="$button/color/button-primary-text"
          onPress={() => {
            setShowMFAForm(false);
          }}
        >
          {t('auth.loginPage.backLink')}
        </Text>
      ) : null}
      {!showMFAForm ? (
        <LoginForm
          onSubmit={onSubmitLogin}
          onPressNeedHelp={onPressNeedHelp}
          onPressSignUp={onPressSignUp}
          showInactivityLogout={showInactivityLogout}
          loginServerError={loginError}
          onDismissError={() => {
            setLoginError(undefined);
          }}
        />
      ) : (
        <MfaForm
          title={t('auth.components.mfaForm.title')}
          description={t('auth.components.mfaForm.description')}
          onSubmit={onSubmitMFA}
          onResend={onResendMFA}
          disabled={isDisabled}
        />
      )}
    </YStack>
  );
}
