import type { AxiosError } from '@cxnpl/api/axios';
import { isAxiosError } from '@cxnpl/api/axios';
import { useLoginUser } from '@cxnpl/api/users';
import { useRouter } from '@cxnpl/router';
import { useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'solito/navigation';
import type { BRAND } from 'zod';
import { useAppDispatch } from 'app/store';
import { appClient } from 'app/utils/appClient';
import type { MfaFormProps } from 'app/provider/StepUpAuth/MfaForm';
import { useAppStorage } from 'app/services/appStorage/useAppStorage';
import { setInactivityLogout } from '../../authSlice';
import type { LoginFormProps } from '../components';
import { createDeviceInfo } from '../../utils/createDeviceInfo';

export const useHandleLogin = () => {
  const { t } = useTranslation();
  const [showMFAForm, setShowMFAForm] = useState<boolean>(false);
  const [tempUsername, setTempUsername] = useState<string>(''); // Save username temporarily for login after mfa verification
  const [tempPassword, setTempPassword] = useState<string>(''); // Save password temporarily for login after mfa verification
  const [mfaChallengeId, setMfaChallengeId] = useState<string>('');
  const [loginError, setLoginError] = useState<string | undefined>(undefined);
  const [{ value: deviceId }] = useAppStorage('deviceId');
  const deviceInfo = createDeviceInfo(deviceId || '');
  const dispatch = useAppDispatch();
  const router = useRouter();
  const params = useSearchParams();
  const login = useLoginUser();
  const [isPending, startTransition] = useTransition();

  const handleLoginResponse = () => {
    dispatch(setInactivityLogout(false));
    const redirectParams = params?.get('redirect');
    const redirectUri = redirectParams && decodeURIComponent(redirectParams);
    router.push(redirectUri || '/home');
    router.refresh(); // Forces a refresh. Fix for sticky non redirect after MFA
  };

  const onSubmitLogin: LoginFormProps['onSubmit'] = async ({ customerNumber, password }, loginForm) => {
    try {
      const response = await login.mutateAsync({
        data: {
          username: customerNumber,
          password,
          loginAlias: 'customerNumber',
          deviceInfo,
          appClient,
        },
      });

      // When login is successful after MFA verification, handle the response
      if ('result' in response && response.result === 'success') {
        startTransition(() => {
          handleLoginResponse();
        });
        return;
      }

      // If MFA is required, show the MFA form and store the temporary username and password for reuse
      if ('result' in response) {
        setTempUsername(customerNumber);
        setTempPassword(password);
        setShowMFAForm(true);
        if ('mfaChallengeId' in response) {
          setMfaChallengeId(response.mfaChallengeId);
        }
      }
    } catch (e) {
      if (isAxiosError(e)) {
        const error: AxiosError = e as AxiosError;
        /**
         * Error handling logic:
         *
         * if error code is 500 or more, an outage or server glitch is assumed: show an alert on top of the form
         * (in this case the form is small, so error will always be visible. Longer forms should also account for scrolling to the error alert)
         * if error code is in the 400s, wrong username or password: show field errors
         * otherwise, unknown cases, also show alert on top
         *
         */
        if (error.response && error.response.status >= 500) {
          setLoginError(t('auth.components.loginForm.loginServerError'));
        } else if (error.response && error.response.status >= 400) {
          // login will provide no signalling, so incorrect customer number or password will always ask for MFA
          // If login somehow fails, show an error just in case
          loginForm.setError('customerNumber', {
            message: t('auth.components.loginForm.invalidCredentialsErrorMessage'),
            type: 'value',
          });
          loginForm.setError('password', {
            message: t('auth.components.loginForm.invalidCredentialsErrorMessage'),
            type: 'value',
          });
        } else {
          setLoginError(t('auth.components.loginForm.loginServerError'));
        }
      } else {
        //error is not an axios error, this should not happen, show error alert just in case
        setLoginError(t('auth.components.loginForm.loginServerError'));
      }
    }
  };

  const onResendMFA: MfaFormProps['onResend'] = async (mfaForm) => {
    mfaForm.startCountdown(30);

    try {
      const response = await login.mutateAsync({
        data: {
          username: tempUsername,
          password: tempPassword,
          loginAlias: 'customerNumber',
          appClient,
          deviceInfo,
        },
      });
      if ('result' in response && 'mfaChallengeId' in response) {
        setMfaChallengeId(response.mfaChallengeId);
      }
    } catch (e) {
      mfaForm.setError('code', {
        message: 'Failed to resend security code',
        type: 'value',
      });
    }
  };

  const onSubmitMFA: MfaFormProps['onSubmit'] = async ({ code }, mfaForm) => {
    try {
      try {
        const response = await login.mutateAsync({
          data: {
            username: tempUsername,
            password: tempPassword,
            loginAlias: 'customerNumber',
            mfaChallengeId,
            mfaCode: code,
            deviceInfo,
            appClient,
          },
        });

        if ('result' in response && response.result === 'success') {
          startTransition(() => {
            handleLoginResponse();
          });
        }
      } catch (e) {
        // Login unsuccessful
        mfaForm.setError('code', {
          message: 'Invalid code',
          type: 'value',
        });
      }
    } catch (e) {
      // MFA unsuccessful
      const error = e as AxiosError;
      mfaForm.setValue('code', '' as string & BRAND<'passwordCode'>);
      mfaForm.setError('code', {
        message: error.response?.data.message || 'Invalid code',
        type: 'value',
      });
    }
  };

  return {
    onSubmitLogin,
    onSubmitMFA,
    onResendMFA,
    showMFAForm,
    setShowMFAForm,
    loginError,
    setLoginError,
    isDisabled: isPending,
  };
};
