'use client';

import { Button, H1, SchemaForm, YStack, Text, FormField, XStack, useForm, Alert } from '@cxnpl/ui';
import type { UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState, useTransition } from 'react';
import branding from '@cxnpl/ui/brand';
import i18n from 'app/i18n/i18n.config';
import { useAppSelector } from 'app/store';
import { useAppStorage } from 'app/services/appStorage/useAppStorage';

export const LoginFormSchema = z.object({
  customerNumber: FormField.Text({
    required_error: `${i18n.t('auth.components.loginForm.customerNumberRequiredErrorMessage')}`,
  })
    .min(1, `${i18n.t('auth.components.loginForm.customerNumberRequiredErrorMessage')}`) // Required since zod will see empty string as valid. Cannot reset zod value back to undefined.
    .max(10)
    .regex(/^[0-9]{10}$/, `${i18n.t('auth.components.loginForm.invalidCustomerNumberErrorMessage')}`)
    .describe(`${i18n.t('auth.components.loginForm.customerNumberInputLabel')}`),
  password: FormField.Password((zString) =>
    zString({
      required_error: `${i18n.t('auth.components.loginForm.passwordRequiredErrorMessage')}`,
    }).min(1, `${i18n.t('auth.components.loginForm.passwordRequiredErrorMessage')}`)
  ).describe(`${i18n.t('auth.components.loginForm.passwordInputLabel')}`),
});

export type LoginFormFields = z.infer<typeof LoginFormSchema>;

export interface LoginFormProps {
  onSubmit: (values: LoginFormFields, form: UseFormReturn<LoginFormFields>) => Promise<void> | void;
  onPressNeedHelp: () => Promise<void> | void;
  onPressSignUp: () => Promise<void> | void;
  showInactivityLogout?: boolean;
  loginServerError: string | undefined;
  onDismissError: () => void;
}

export function LoginForm({
  onSubmit: onSubmitCallback,
  onPressNeedHelp,
  onPressSignUp,
  showInactivityLogout,
  loginServerError = undefined,
  onDismissError,
}: LoginFormProps) {
  const [isPending, startTransition] = useTransition();
  const [isCallingOnSubmit, setIsCallingOnSubmit] = useState(false);

  const { t } = useTranslation();
  const form = useForm(LoginFormSchema);

  const { isSubmitting } = form.formState;
  const isSubmittingForm = isSubmitting || isPending || isCallingOnSubmit;

  const [{ value: customerNumberPreviousLogin }] = useAppStorage('customerNumber');
  const customerNumberSignup = useAppSelector((state) => state.signup.customerNumber);
  const customerNumber = customerNumberSignup || customerNumberPreviousLogin || '';

  // Redux store may populate customer number after first render
  useEffect(() => {
    form.setValue('customerNumber', customerNumber);
  }, [customerNumber, form]);

  const onSubmit = useCallback(
    (values: LoginFormFields) => {
      startTransition(async () => {
        setIsCallingOnSubmit(true);
        try {
          await onSubmitCallback(values, form);
        } finally {
          setIsCallingOnSubmit(false);
        }
      });
    },
    [form, onSubmitCallback]
  );
  return (
    <YStack gap="$space.2xl" width="100%" justifyContent="center" alignItems="center">
      <YStack justifyContent="center" alignItems="center" gap="$space.lg">
        <H1 textAlign="left" color="$foreground/surface-default">
          {branding.productName}
        </H1>
        <Text variant="bodyMedium">{t('auth.components.loginForm.title')}</Text>
      </YStack>
      {showInactivityLogout ? (
        <Alert severity="info" variant="inline">
          {t('auth.components.loginForm.inactivityLoggedOutMessage')}
        </Alert>
      ) : null}
      {loginServerError !== undefined ? ( //Checking explicitly for undefined in case a blank translation is passed
        <Alert
          severity="danger"
          variant="inline"
          testID="login-error-notification"
          onDismiss={() => {
            onDismissError();
          }}
        >
          {loginServerError}
        </Alert>
      ) : null}
      <SchemaForm
        form={form}
        formProps={{
          disabled: isSubmittingForm,
        }}
        onSubmit={onSubmit}
        schema={LoginFormSchema}
        defaultValues={{
          customerNumber,
          password: '',
        }}
      />
      <XStack alignSelf="flex-start">
        <Text
          variant="linkMedium"
          width="max-content"
          link
          color="$button/color/button-primary-text"
          onPress={() => {
            void onPressNeedHelp();
          }}
        >
          {t('auth.components.loginForm.needHelpLink')}
        </Text>
      </XStack>
      <YStack alignItems="center" justifyContent="center" gap="$lg" width="100%">
        <Button
          onPress={() => {
            onDismissError();
            void form.handleSubmit(onSubmit)();
          }}
          mode="primary"
          variant="filled"
          size="lg"
          loading={isSubmittingForm}
          disabled={isSubmittingForm}
          width="100%"
        >
          {t('auth.components.loginForm.submitButtonLabel')}
        </Button>
        <XStack gap="$sm">
          <Text variant="bodyMedium" color="$foreground/surface-default">
            {t('auth.components.loginForm.newAccountText')}
          </Text>
          <Text
            variant="linkMedium"
            width="max-content"
            link
            color="$button/color/button-primary-text"
            onPress={() => {
              void onPressSignUp();
            }}
          >
            {t('auth.components.loginForm.signUpLink')}
          </Text>
        </XStack>
      </YStack>
    </YStack>
  );
}
