import { useSearchParams } from 'solito/navigation';
import { baseUrl } from '@cxnpl/api/baseUrl';
import { useEffect } from 'react';
import { useAppStorage } from 'app/services/appStorage/useAppStorage';

export function LoginAuth0Page() {
  const params = useSearchParams();
  const redirectParams = params?.get('redirect');
  // Get the current origin to redirect back to webapp
  const currentOrigin = location.origin;
  const redirectUri = redirectParams
    ? encodeURIComponent(`${currentOrigin}/${decodeURIComponent(redirectParams)}`)
    : encodeURIComponent(`${currentOrigin}/home`); // Redirect home in fail-case

  const [{ value: deviceId }] = useAppStorage('deviceId');

  /**
   * Majority of heavy lifting is handled by the cxnpl backend.
   * Just move from this page to a different page.
   *  */
  useEffect(() => {
    if (!deviceId) {
      // A deviceId should be generated within the StoreProvider
      return;
    }
    window.location.href = `${baseUrl}/v2/session/web-login?redirectUri=${redirectUri}&deviceId=${deviceId}`;
  }, [deviceId, redirectUri]);
  return null;
}
