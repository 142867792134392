import type { DeviceState } from '@cxnpl/api/types';
import * as Device from 'expo-device';
import { Platform } from 'react-native';

export const createDeviceInfo = (deviceId: string): DeviceState => {
  if (Platform.OS === 'web') {
    return {
      deviceId,
      deviceType: 'WEB',
      webFields: {
        model: typeof navigator !== 'undefined' ? navigator.userAgent : '',
      },
    };
  }
  return {
    deviceId,
    deviceType: 'MOBILE',
    mobileFields: {
      deviceType: 'MOBILE',
      model: `${Device.modelName}`,
      osName: Platform.OS,
      osVersion: Platform.Version.toString(),
      isTablet: Boolean(Device.deviceType === Device.DeviceType.TABLET),
    },
  };
};
